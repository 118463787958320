import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns = ({
  location,
}) => {
  const title =
    "Is it possible to create a list of past retweet campaign participants?"
  const list = [
    { title: "Frequently Asked Questions", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="We have a Twitter retweet campaign (Instant Win) that was conducted without using PARKLoT. Can I get a list of users who participated in this campaign?"
          pagepath={location.pathname}
        />
        <Breadcrumb title="Frequently Asked Questions" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              I have a Twitter retweet campaign that was conducted without using
              PARKLoT. Can I retrieve the users who participated in this
              campaign later?
            </h1>
            <p className="qa-page__answer">
              Please contact us.
              <br />
            </p>
            <p className="qa-page__text">
              Due to Twitter specifications, retweet users can usually only get
              up to the most recent 100 retweets.
              <br />
              However, in some situations, retweet users can be obtained using
              other methods.
              <br />
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">
                  Without using a dedicated tool, retweet users can only get the
                  last 100 retweets.
                </h2>

                <p className="qa-page__text">
                  When conducting a retweet campaign, here are some points that
                  require special attention.
                </p>

                <p className="qa-page__text">
                  If you try to check retweet users from the Twitter
                  application, you can actually only see the most recent 100
                  users.
                </p>

                <h3 className="qa-page__h3">
                  PARKLoT's retweet user collection mechanism
                </h3>

                <p className="qa-page__text">
                  PARKLoT has a system running in the background constantly
                  collecting data on retweeting users from the moment the
                  campaign starts until the end of the campaign.
                </p>

                <h3 className="qa-page__h3">Plan your use of the tool!</h3>

                <p className="qa-page__text">
                  Please consider using the tool when conducting a retweet
                  campaign so that you do not panic when conducting the drawing.
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default RetrieveUsersWhoHaveParticipatedInPastRetweetCampaigns
